<template>
	<div class="coupon">
		<div class="my-title clearfix">
			<span class="">领券中心</span>
		</div>
		<div class="coupon-list clearfix">
			<el-empty :image-size="50" description="暂无优惠券" v-if="list.length==0"></el-empty>
			<ul>
				<li v-for="(item,index) in list" :key="index">
					<div class="left">
						<p class="price" v-if="item.coupon_type == CouponTypeEnum.FULL_DISCOUNT.value">
							<em>￥</em><span>{{ item.reduce_price }}</span>
						</p>
						<p class="price" v-if="item.coupon_type == CouponTypeEnum.DISCOUNT.value">
							<span>{{ item.discount }}折</span>
						</p>
						<p class="tips">满{{ item.min_price }}元可用</p>
					</div>
					<div class="right">
						<p class="title">• {{ item.name }}</p>
						<p class="tips">
							<em class="time" v-if="item.expire_type == 10">• 领取{{ item.expire_day }}天内有效</em>
							<em class="time" v-if="item.expire_type == 20">• {{ item.start_time }}~{{ item.end_time }}</em>
						</p>
						<p class="btn">
							<span class="receive border" v-if="item.state.value" @click="receive(item.coupon_id)">立即领取</span>
							<span class="receive" v-else>{{ item.state.text }}</span>
						</p>
					</div>
					<div class="style">
						<div class="dot dot1"></div>
						<div class="line"></div>
						<div class="dot dot2"></div>
					</div>
					<div class="corner-marker">{{ CouponTypeEnum[item.coupon_type].name }}</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import * as CouponApi from '@/api/coupon'
	import * as MyCouponApi from '@/api/myCoupon'
	import { CouponTypeEnum } from '@/common/enum/coupon'
	
	export default {
		data() {
			return {
				// 枚举类
				CouponTypeEnum,
				// 优惠券列表
				list: [],
			}
		},
		created() {
			// 获取优惠券列表
			this.getCouponList()
		},
		methods: {
			/**
			 * 获取优惠券列表
			 * @param {bool} load 是否显示loading弹窗
			 */
			getCouponList(load = true) {
			  const app = this
			  CouponApi.list({}, { load })
			    .then(result => {
			      app.list = result.data.list
			    })
			},
			// 立即领取
			receive(couponId) {
			  const app = this
			  MyCouponApi.receive(couponId)
			    .then(result => {
			      // 显示领取成功提示
				  app.$message.success(result.message)
			      // 刷新优惠券列表
			      app.getCouponList(false)
			    })
			}
		}
	}
</script>

<style lang="scss">
	@import "../../assets/css/coupon.scss";
</style>
